import { SearchField } from '@features/SearchField';

import type { FC } from 'react';

export const Header: FC = () => {
  return (
    <header className="flex justify-end w-full gap-3">
      <SearchField />
      {/* <NotificationButton /> */}
    </header>
  );
};
