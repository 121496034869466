import { isAxiosError } from 'axios';
import { HttpStatusCode } from 'axios';

import { ApiErrorKind } from '@shared/types';

import { text } from './text';

import type { ApiError, CommonApiError } from '@shared/types';

export const DEFAULT_ERROR_MATCH = {
  [HttpStatusCode.BadRequest]: ApiErrorKind.VALIDATION,
  [HttpStatusCode.Unauthorized]: ApiErrorKind.UNAUTHORIZED,
};

export const normalizeApiError = <Detail = string, Error = ApiError<Detail>>(
  error: unknown,
  config: Partial<Record<HttpStatusCode, ApiErrorKind>> = DEFAULT_ERROR_MATCH,
): Error | CommonApiError => {
  if (isAxiosError(error) && error.response?.status) {
    const status = error.response?.status as HttpStatusCode;
    const detail = error.response?.data?.detail;
    const id = config?.[status];

    if (id) {
      return { id, detail } as Error;
    }
  }

  return { id: ApiErrorKind.COMMON, detail: text.COMMON_ERROR } as Error;
};
