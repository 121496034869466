export enum ApiErrorKind {
  COMMON = 'COMMON_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  VALIDATION = 'VALIDATION_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED_ERROR',
}

export type FormFieldError = { message: string; code: string };

export type ApiError<Id = ApiErrorKind, Detail = string> = {
  id: Id;
  detail: Detail;
};

export type CommonApiError = ApiError<ApiErrorKind.COMMON>;

export type ValidationApiError<Fields> = ApiError<
  ApiErrorKind.VALIDATION,
  Partial<Record<keyof Fields, FormFieldError[]>>
>;

export type ExcelValidationError = Record<
  string,
  {
    message: string;
    code: string;
  }
>;
